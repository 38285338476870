@mixin dashboard-title-font{
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
}




.dashboard
{
   .menu-bar{
    box-shadow: 0 4px 55px 0 #20222319;
    background-color: #ffffff;
    min-height:70px ;

        .nav-bar-dropdown
        { 

             @include dashboard-title-font;
            font-size: 14px;
            
            text-align: center;
            color: #2c2f32;
            font-weight: 500;
            a{
                color: #2c2f32;
            }
         
        }



   }
   .children-area{
       min-height: 500px;
   }

   .single-well{
    
    min-height: 90%;
    border-radius: 20px;
    box-shadow: 0 4px 55px 0 #20222319;
    background-color: #ffffff;

        .title{
        
            font-family: Montserrat;
            font-size: 45px;
            font-weight: 800;
            text-align: center;
            color: #2c2f32;
        }
           .subtitle{
            @include dashboard-title-font;
            text-align: center;
            color: #4a4d4f;
           }

   }

}

.wallet-explore {

    .top-part {
        background-image: url(/landing_assets/blue_back.svg);
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 425px;

        .title {

            @include dashboard-title-font;
            text-align: center;
            color: #ffffff;
        }

        .wallet-bar {

            input {
                 
                border: solid 1px #dedede;
                background-color: #ffffff19;
                @include dashboard-title-font;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                color: #ffffff;
            }

        }

        .left-section {
            color: #ffffff;
            font-family: Montserrat;

            .form-label {
                margin-bottom: 0px;
                font-size: 12px;
                line-height: 2.08;
            }

            .form-control-plaintext {
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.56;
                padding: 0px;
            }
        }

        .right-section {
            min-height: 230px;

            img {
                width: 80px;
                height: 80px;
            }

            .btn {
                width: 80px;
            }
        }
    }

    .main-info {
        border-radius: 10px;
        box-shadow: 0 4px 30px 0 #2022230c;
        background-color: #ffffff;
        color: #2c2f32;

        .title {
            @include dashboard-title-font;
            font-size: 26px;
            text-align: center;
            border-bottom: 1px solid #dbd9d3;
        }

        .main-balance-info {
            @include dashboard-title-font;
        }

        .other {
            @include dashboard-title-font;
            font-weight: initial;
            text-align: center;
            color: #4a4d4f;
        }
    }

    .summary {

        .income, .expense {
            border-radius: 10px;
            box-shadow: 0 4px 30px 0 #2022230c;
            @include dashboard-title-font;
            color: #fff;
        }

        .income {
            background-color: #44d7b6;
        }

        .expense {
            background-color: #ea6f7e;
        }
    }

    .transaction-config {

        .title {

            @include dashboard-title-font;
            color: #2c2f32;
        }

        .dropdown .btn {
            &:focus {
                border: 0px;
                box-shadow: none;
            }

            @include dashboard-title-font;
            font-size: 16px;
            font-weight: initial;
            line-height: 1.88;
            color: #2c2f32;
            border: 0px;
            background-color: transparent;
        }

        .mode-check-box {
            @include dashboard-title-font;
            font-size: 14px;
            line-height: 1.71;
            color: #4a4d4f;
            background-color: #f8f8f8;
            border-radius: 10px;
        }

        .paging-info-bar {
            @include dashboard-title-font;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.88;
            color: #2c2f32;
        }
    }

    .transaction-bar {
        border-radius: 20px;
        box-shadow: 0 4px 55px 0 #20222319;
        background-color: #ffffff;

        .header-part {

            @include dashboard-title-font;
            border-bottom: 1px solid #dbd9d3;

            &:span {
                @include dashboard-title-font;
                line-height: 1.25;
                color: #2c2f32;
            }
        }

        .body-part {
            @include dashboard-title-font;
            font-size: 16px;

            .item-label {
                @include dashboard-title-font;
                font-size: 12px;
                font-weight: normal;
                line-height: 2.08;
                color: #2c2f32;
            }

            .item-value {
                @include dashboard-title-font;
                font-size: 16px;
                font-weight: 800;
                color: #4a4d4f;
            }
        }
    }

    .transaction-pager {
    }
}


.xrp-tr-box{

    border-radius: 10px;
    border: solid 1px #dedede;
    
}
