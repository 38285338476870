

@font-face {
    font-family: 'MontserratBold';
    src: url("/fonts/Montserrat-Bold.ttf") format("ttf");
    font-style: normal;
    font-weight: 800;
}


@font-face {
    font-family: 'Montserrat';
    src: url("/fonts/Montserrat-Regular.ttf") format("ttf");
    font-style: normal;
}



@-webkit-keyframes rotation_anime_pattern {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}

@keyframes rotation_anime_pattern {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}

@mixin rotation_anime_duration {
    -webkit-animation: rotation 5s linear;
}

@mixin rotation_anime {

    $animation_name: rotation_anime_pattern;
    $animation_duration: 0.7s;

    -webkit-animation-name: $animation_name; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: $animation_duration; /* Safari 4.0 - 8.0 */
    animation-name: $animation_name;
    animation-duration: $animation_duration;
}

.navbar-light .navbar-toggler-icon
{
    background-color: white;
}


a {

    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #2dbee4;

    &:hover {
        text-decoration: none;
    }
}

body {
    letter-spacing: normal;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}




.wallet-pan-landing {
    background-color: #fff;

    .section-head{
        .left-part{
            .banner-Text{
                 
                   h2{  width: 400px;
                    height: 276px;
                   }
            }
         
        }
    }

    .section-operation {
        background: url(/landing_assets/section-operation-background.svg);

           a {
              &:hover{
                .iconic-box 
                {
                   border:solid 1px #2dbee4; 
                }
                       
              } 
           }


        @media (max-width: 600px) {
            background: none;
            margin-top: 20px;
            padding-top: 10px;
            background-color: #eff3f9;
        }

        background-repeat: no-repeat;
        min-height: 515px;
        background-size: cover;
        background-position-y: bottom;
        padding: 80px 0px 80px 0px;
    }

    .section-about {
        margin-top: -1px;
        padding: 100px 0 100px 0;
        background-color: #eff3f9;

   
        .landing-subtitle {
            margin: 60px auto 0 auto;
        }

        .section-about-boxes-wrapper {
            margin-top: 100px;
            padding-left: 10px;
        }
    }

    .section-faqs-one {
        background: url(/landing_assets/faqs-background.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: top;
        padding: 80px 0px 80px 0px;

        
    }

    .section-faqs-two {
        min-height: 200px;
        margin-bottom: 110px;

        .foot-note {
            padding-top: 39px;
            font-family: Montserrat;
            font-size: 16px;
            line-height: 1.88;
            color: #2c2f32;

            a {
                margin: 0 10px;
                line-height: 1.88;
                color: #2dbee4;
            }
        }
    }
    /*general items*/
    .accordion {
        max-width: 1300px;

        .card-wrapper {
            border-top: solid 1px #dcddde;

            .card {
                border: 0px solid transparent;
                margin: 10px 0px;
                padding: 30px 20px;

                @media (max-width: 600px) {
                    padding: 30px 0px;
                }

                .card-header {
                    background-color: transparent;
                    border-bottom: none;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1.5;
                    color: #2c2f32;

                    @media (max-width: 600px) {
                        padding: 0.75rem 0rem;
                    }

                    span {
                        @media (max-width: 600px) {
                            max-width: 90%;
                            display: inline-block;
                        }
                    }

                    img {
                        float: right;
                    }

                    .card-header-plus {
                        @include rotation_anime;
                        display: block;
                    }

                    .card-header-minus {
                        display: none;
                    }

                    .card-header-plus, .card-header-plus {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        &:last-child {
            border-bottom: solid 1px #dcddde;
        }

        .fillblue {
            .card {
                background-color: #4ec8e80c;

                .card-header {
                    font-size: 20px;
                    font-weight: 800;

                    .card-header-plus {
                        display: none;
                    }

                    .card-header-minus {
                        @include rotation_anime;
                        display: block;
                    }
                }
            }
        }
    }



    .landing-title {
        font-size: 45px;
        font-weight: 800;
        text-align: center;
        color: #2c2f32;
    }

    .landing-subtitle, .landing-subtitle P {
        font-family: Montserrat;
        font-size: 20px;
        line-height: 1.5;
        text-align: center;
        color: #4a4d4f;
    }

    .landing-subtitle P {
        width: 70%;
    }


    @mixin box-titles {
        font-weight: 800;
        font-size: 20px;
        line-height: 1.5;
        color: #2c2f32;
    }

    @mixin box-subtitles {
        font-family: Montserrat;
        font-size: 16px;
        line-height: 1.56;
        color: #4a4d4f;
    }


    .iconic-box {
        border-radius: 20px;
        box-shadow: 0 4px 55px 0 #20222319;
        background-color: #ffffff;
        margin: 15px auto;
        text-align: center;
        padding: 10px;

        @media (max-width: 767px) {
            margin: 10px;
            transform: scale(0.9);
        }

        img {
            width: 95px;
            height: 85px;
            margin: 40px auto 0 auto;
            display: block;
        }

        .title {
            padding: 0 30px;
            height: 30px;
            margin-top: 40px;
            @include box-titles;

            @media (max-width: 1000px) {
                padding: 0 5px;
            }
        }

        .subtitle {
            padding: 0 30px;
            margin-top: 15px;
            min-height: 50px;
            @include box-subtitles;

            @media (max-width: 1000px) {
                padding: 0 5px;
            }
        }
    }




    .vertical-iconic-box {
        margin-bottom: 60px;

        .vertical-iconic-box-img-wrapper {
            display: inline-block;
            margin: 0 30px 0 0;
            width: 95px;
            height: 85px;
            vertical-align: top;
        }

        .vertical-iconic-box-text-wrapper {
            display: inline-block;
            max-width: 390px;
            padding: 0 10px;

            .title {
                color: #2c2f32;
                @include box-titles;
            }

            .subtitle {
                margin-top: 10px;
                font-family: Montserrat;
                letter-spacing: -0.2px;
                color: #4a4d4f;
                @include box-subtitles;
            }
        }
    }
}




.twin-section {

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column-reverse;
    }

    .left-part {

        @media (max-width: 767px) {
            float: right;
            position: relative;
        }

        .logo-wrapper {
            padding-top: 60px;

            img {
                width: 69%;
                height: 25px;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }

        .banner-Text {
            margin: 125px 0 35px 0;

            h2 {
                font-family: MontserratBold;
                font-size: 75px;
                font-weight: 800;
                color: #2c2f32;
                margin-bottom: 37px;
            }

            p {
                max-width: 420px;
                display: block;
                font-family: Montserrat;
                font-size: 20px;
                line-height: 1.75;
                color: #4a4d4f;
            }
        }
    }

    .right-part {
        background: url(/landing_assets/picture-background.svg);
        background-repeat: no-repeat;
        min-height: 400px;

        @media (max-width: 767px) {
            float: left;
            position: relative;
        }

        & > .row {
            position: absolute;
            z-index: 4;
        }


  


        .navbar {
            position: relative;
            z-index: 5;
            padding-top: 35px;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;

            .navbar-toggler {
                &:focus {
                    border: solid 1px #2dbee4;
                    outline: 5px auto #2dbee4;
                }
            }


            .navbar-nav {


                &:first-child {
                    margin-left: 106px;

                    @media (max-width: 1439px) {
                        margin-left: 10px;
                    }
                }

                .nav-link {
                    width: 47px;
                    height: 18px;
                    margin-right: 39px;
                    color: white;
                    top: -11px;
                    position: relative;

                    &:hover {
                        text-decoration: none;
                    }

                    @media (max-width: 1439px) {
                        margin-right: 20px;
                    }
                }
            }



            .diparted-nav-section {
                float: right;
                margin-left: 80px;

                .nav-link {
                    top: -1px;
                    position: relative;
                }

                .btn-outline-primary {
                    width: 120px;
                    height: 39px;
                    border-radius: 10px;
                    border: solid 1px #2dbee4;
                    color: #fff !important;
                }


                @media (max-width: 1439px) {
                    margin-left: 40px;
                }
            }


            .show {
                background-image: linear-gradient(238deg, rgba(48, 33, 150, 0) 135%, rgba(18, 168, 207, 0.5) -12%), linear-gradient(to bottom, #030a41, #030a41);
                padding: 10px;
                opacity: 0.8;
                border-radius: 10px;


                .navbar-nav {
                    margin-top: 10px;

                    &:first-child {
                        margin-left: 0px;
                    }

                    a {
                        margin-top: 10px;
                        cursor: pointer;
                    }
                }

                .diparted-nav-section {
                    float: left;
                    margin: 0px;
                    width: 100%;

                    a {
                        top: 0px;
                        margin-top: 0px;
                    }

                    Button {
                        border: solid 1px #2dbee4;
                        margin: 10px auto;
                    }
                }
            }
        }
    }
}



.login {

    .left-part {

        .banner-Text {
            margin: 100px auto 35px auto;
            max-width: 437px;

            h2, span {
                text-align: center;
                font-family: Montserrat;
            }

            h2 {
                font-size: 30px;
                font-weight: bold;
                color: #2c2f32;
                text-align: left;
                margin-bottom: 20px;
            }

            .hint-bar {
                font-size: 20px;
                line-height: 1.75;
                color: #4a4d4f;
            }
        }

        .custom-form {
            max-width: 400px;
            margin-bottom: 60px;

            button {
                width: 100%;
            }
        }
    }

    .right-part {
        .logo-wrapper {
            margin-top: 100px;
        }

        .subtitle {
            font-size: 28px;
            color: #ffffff;
            margin-top: 20px;
        }
    }

  
}



.general-background{
    background-image :  url(/landing_assets/general_background.svg);
}



 